
html {
  background-color: #282c34;
}

.app {
  text-align: center;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-container {
  margin: 50px;
}

textarea {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.url-info {
  padding: 30px;
  text-align: left;
  background: #1a1a1f;
  font-family: monospace;
  word-wrap: anywhere;
  max-width: 900px;
  margin-bottom: 200px;
}

.url-info .part {
  margin: 5px;
}

.part.param {
  margin-left: 30px;
}

.url-invalid {
  word-wrap: anywhere;
}
